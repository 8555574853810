<script setup lang="ts">
import { Models } from '~/types/models'

const props = defineProps({
    data: {
        type: Object as PropType<Models.CollectionResource>,
        required: true,
    },
    type: {
        type: String as PropType<'homepage' | 'normal' | 'profile'>,
        default: 'normal',
    },
    textColor: {
        type: String,
        default: 'black',
    },
})

const path = computed(() => {
    return  (props.data.type === 'exhibit'
        ? '/exhibiciones/'
        : props.data.type === 'collection'
        ? '/series/'
        : '/proyectos-especiales/') + props.data.slug;
})

const styles = computed(() => {
    return {
        image:
            props.type === 'homepage'
                ? 'h-[250px] lg:h-[400px]'
                : props.type === 'profile'
                ? 'h-[270px]'
                : 'h-[450px]',
        icon:
            props.type === 'homepage'
                ? 'text-white border-white hover:bg-white hover:text-black'
                : 'text-black border-black  hover:bg-black hover:text-white',
        description:
            props.type === 'homepage' ? 'text-[#E7E7E7] border-gray-600' : 'text-[#606060] border-black',
    }
})

</script>

<template>
    <article class="collection">
        <div class="collection__image" :class="styles.image">
            <NuxtLink :to="path">
                <img
                    :src="props.data.cover || ''"
                    :alt="props.data.title || 'Portada'"
                    :onerror="`this.src=&quot;/no-image.jpg&quot;`"
                />
            </NuxtLink>
            <UtilsBookmark class="icon" v-model:post="props.data" />
        </div>
        <NuxtLink :to="path"
            ><img
                v-if="props.data.title_image"
                class="collection__brand"
                :src="props.data.title_image || ''"
                alt="Coleccion"
                :onerror="`this.src=&quot;/no-image.jpg&quot;`"
            />
            <h2 class="collection__title" :class="'text-' + textColor" v-else>
                {{ props.data.title }}
            </h2></NuxtLink
        >
        <div class="collection__year-go">
            <p>{{ props.data.year }}</p>
            <NuxtLink :to="path" :class="styles.icon">
                <UnoIcon class="i-mdi:arrow-right" />
            </NuxtLink>
        </div>
        <h3 
            v-if="props.data.content && props.data.content.length > 0" 
            class="collection__description" 
            :class="styles.description" 
            v-html="props.data.content"
        >
        </h3>
    </article>
</template>

<style lang="postcss" scoped>
.collection {
    &__image {
        @apply relative;
        & img {
            @apply w-full h-full object-cover;
        }
        & .icon {
            @apply absolute text-white bg-transparent bottom-4 left-4 text-xl border-2  px-1 pt-1;
        }
    }
    &__brand {
        @apply pt-6 h-[70px];
    }
    &__title {
        @apply text-lg font-semibold;
    }
    &__year-go {
        @apply flex justify-between items-center mt-5;
        & p {
            @apply font-Pathway text-site-primary font-semibold text-base md:text-lg;
        }
        & a {
            @apply flex items-center gap-1 text-2xl border py-1 px-1 transition-all;
        }
    }
    &__description {
        @apply font-Inter text-sm md:text-[15px] mt-3 pt-3 border-t line-clamp-2;
    }
}
</style>
